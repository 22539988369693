import funnyDudeImg from "url:/src/assets/avatars/funny-dude.jpg";
import niceDudeImg from "url:/src/assets/avatars/nice-dude.jpg";
import whiteDudeImg from "url:/src/assets/avatars/white-dude.jpg";
import hungryDudeImg from "url:/src/assets/avatars/hungry-dude.jpg";

import star_outlined from "url:/src/assets/star-line.svg";
import star_filled from "url:/src/assets/star.svg";


const data = {
   1: {
      "name": "Funny Dude",
      "rating": "4",
      "date": "21 Jan 2021",
      "comment": "Very good people. I enjoyed their services.",
      "pic": funnyDudeImg,
   },
   2: {
      "name": "Nice Dude",
      "rating": "1",
      "date": "21 Jan 2021",
      "comment": "Very good people. I enjoyed their services.",
      "pic": niceDudeImg,
   },
   3: {
      "name": "White Dude",
      "rating": "2",
      "date": "21 Jan 2021",
      "comment": "Very good people. I enjoyed their services.",
      "pic": whiteDudeImg,
   },
   4: {
      "name": "Hungry Dude",
      "rating": "3",
      "date": "21 Jan 2021",
      "comment": "Very good people. I enjoyed their services.",
      "pic": hungryDudeImg,
   }
}

let ITEMCOUNT = 0;
let POSITION = 0;

for (const x in data) {
   if (Object.hasOwnProperty.call(data, x)) {
      const el = data[x];
      const destiny = document.querySelector('.testimonials');
      const container = document.createElement('div')
      container.innerHTML = `<div class="testimonial-meta">
      <img src="${el.pic}" alt="Avatar" class="testimonial-pic">
      <div>
         <h3 class="bold_700 blue_color">${el.name}</h3>
         <div class="testimonial-stars" data-rating="${el.rating}">
            <img src=${star_outlined} alt="rating-star">
            <img src=${star_outlined} alt="rating-star">
            <img src=${star_outlined} alt="rating-star">
            <img src=${star_outlined} alt="rating-star">
            <img src=${star_outlined} alt="rating-star">
         </div>
      </div>
      <p class="date">${el.date}</p>
   </div>
   <div class="testimonial-comment">
      <p class="blue-light blue_color">
         ${el.comment}
      </p>
   </div>`;
      container.classList.add('testimonial-container')
      destiny.appendChild(container);
      ITEMCOUNT = ++ITEMCOUNT
   }
}

const ratings = document.querySelectorAll('.testimonial-stars')

ratings.forEach(el => {
   let rating = el.dataset.rating
   let x = 0;
   while (x < rating) {
      const img = el.children;
      img[x].src = star_filled;
      x++
   }
});


const fowardButton = document.querySelector('.t_control_forward');
const backButton = document.querySelector('.t_control_backward');
const testimonials = document.querySelectorAll('.testimonial-container');
const testimonial = document.querySelector('.testimonials');
const MARGIN = 70;
let pageWidth = document.body.clientWidth;
let cWidth = parseInt(testimonials[0].clientWidth);
let sWidth = parseInt((((pageWidth - (cWidth)) / 2) - MARGIN));
let tWidth = parseInt(cWidth + MARGIN * 2);

if (POSITION == 0) {
   testimonials[0].classList.add('active');
   testimonial.style.transform = `translateX(${sWidth}px)`;
}

window.onresize = () => {
   setTimeout(() => {
      pageWidth = document.body.clientWidth;
      cWidth = parseInt(testimonials[0].clientWidth);
      sWidth = parseInt((((pageWidth - (cWidth)) / 2) - MARGIN));
      tWidth = parseInt(cWidth + MARGIN * 2);

      if (POSITION == 0) {
         testimonials[0].classList.add('active');
         testimonial.style.transform = `translateX(${sWidth}px)`
      } else {
         move();
      }
   }, 500);
}

const meter = document.querySelector('.testimonial_meter');
const meterHeight = meter.clientHeight;
const meterStep = meterHeight / testimonials.length;

const moveMeter = (step) => {
   meter.style.height = `${meterHeight - (meterStep * step)}px`
}


const updatePosition = (direction) => {
   if (direction > 0) {
      if (POSITION == testimonials.length - 1) {
         POSITION = 0;
      } else {
         POSITION = ++POSITION;
      }
   } else if (direction < 0) {
      if (POSITION == 0) {
         POSITION = testimonials.length - 1;
      } else {
         POSITION = --POSITION;
      }
   }
}

const move = (direction) => {
   updatePosition(direction)
   testimonials.forEach(el => {
      el.classList.remove('active');
   });
   testimonials[POSITION].classList.add('active');
   testimonial.style.transform = `translateX(${sWidth - (tWidth * POSITION)}px)`

   moveMeter(POSITION)
}

fowardButton.onclick = () => move(1);
backButton.onclick = () => move(-1);

// testimonial.addEventListener('dragover', (e)=>{
//    console.log(e)
//    // if (scrollY > 90) {
//    //    header.classList.add('scrollOn')
//    // }else{
//    //    header.classList.remove('scrollOn')
//    // }
// })